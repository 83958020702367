<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="listQuery.school_id" placeholder="请选择学校">
        <el-option
          v-for="item in schoolList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="date"
        type="month"
        placeholder="选择月"
        style="margin-left: 10px"
        @change="change"
      >
      </el-date-picker>
      <el-button class="filter-item" type="primary" @click="getSchedule">
        搜索
      </el-button>
    </div>
    <el-calendar v-model="value">
      <template slot="dateCell" slot-scope="{ date, data }">
        <div :class="data.isSelected ? 'is-selected' : ''">
          {{ data.day.split("-").slice(1).join("-") }}
          <div
            v-for="item in events"
            :key="item.id"
            v-if="data.day == item.date"
            class="dateStyle"
          >
            {{
              item.start_at +
              "-"+
              item.end_at +
              " " +
              item.other_course_school.other_course.name
            }}
          </div>
        </div>
      </template>
    </el-calendar>
    <!-- <fullcalendar :events='events' lang="zh"></fullcalendar> -->
  </div>
</template>
<script>
import fullcalendar from "vue-fullcalendar";
import request from "@/utils/request";
export default {
  components: {
    fullcalendar,
  },
  data() {
    return {
      date: "",
      events: [],
      listQuery: {
        school_id: "",
        year: "",
        month: "",
      },
      value: new Date(),
      schoolList: [],
    };
  },
  props() {},
  created() {
    this.getSchoolList();
    this.getSchedule();
  },
  mounted() {},
  methods: {
    change(e) {
      this.value = e;
      this.listQuery.year = e.getFullYear();
      this.listQuery.month = e.getMonth() + 1;
      this.getSchedule();
    },
    getSchoolList() {
      request({
        url: "/api/teacherend/otherCourse/schoolList",
        method: "get",
        params: {},
      }).then((response) => {
        if (response.code == 0) {
          this.schoolList = response.data;
        }
      });
    },
    getSchedule() {
      request({
        url: "/api/teacherend/otherCourse/schedule",
        method: "get",
        params: this.listQuery,
      }).then((res) => {
        if (res.code == 0) {
          this.events = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" >
.filter-container {
  display: flex;
  align-items: center;
  .filter-item {
    margin: 0;
    margin-left: 10px;
  }
}
.el-calendar__button-group {
  display: none !important;
}
.el-calendar-day {
  height: 110px !important;
  overflow: hidden;
  overflow-y: auto;
}
.dateStyle {
  color: #000;
  background: #c7e6fd;
  margin-top: 10px;
}
</style>